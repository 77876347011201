/* General */

:root {
  --accent-color: rgb(255, 255, 255);
  --secondary-color: rgb(222, 222, 222);
}

@font-face {
  font-family: Manrope-Thin;
  src: url("./Styles/Fonts/manrope-thin.otf");
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: var(--secondary-color);
  font-family: 'Manrope-Thin', 'Lucida Sans', Arial, sans-serif;
  letter-spacing: 0.05rem;
  overflow: overlay;
}

#root {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/img/background.jpg");
  background-attachment: fixed, fixed;
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position-x: center, center;
}

@media (max-width:500px) {
  #root {
    background-size: auto, 1000px;
    background-position-x: right, -500px;
  }
}

#page-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Layout */

main {
  padding-top: 8%;
  flex: 1;
  margin: 0 18%;
}

footer {
  min-height: 35px;
  margin: 0 10%;
  padding-top: 15px;
  padding-bottom: 5px;
}

nav {
  padding: 0 18%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

nav ul {
  list-style-type: none;
  display: inline-block;
}

nav ul li {
  display: inline-block;
  margin-right: 10px;
}

nav ul li a {
  color: var(--secondary-color);
  transition: color 0.2s;
}

nav ul li a:hover {
  color: var(--accent-color);
}

ul[role="navigation"] {
  list-style: none;
  display: flex;
}

ul[role="navigation"] li {
  cursor: pointer;
  margin: 0 5px;
}

ul[role="navigation"] li a:hover {
  color: var(--accent-color);
}

ul[role="navigation"] li.selected a {
  color: var(--accent-color);
}

.hamburger {
  display: none !important;
}

#social a img:hover {
  filter: brightness(200%);
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.01);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

/* Main texts */

a {
  text-decoration: none;
  color: var(--secondary-color);
}

h1 {
  letter-spacing: 0.5rem;
  color: var(--accent-color);
  font-size: 4rem;
  margin-bottom: 10px;
}

h2#pianist {
  font-size: 1.1rem;
  margin-bottom: 50px;
}

#main-youtube-video {
  width: 100%;
  height: 40vw;
}

/* Blocks styles */

.block {
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  margin-bottom: 15px;
}

/* Blocks links */

.block a {
  color: black;
  background-color: var(--secondary-color);
  padding: 5px 10px;
  transition: color 0.3s, background-color 0.3s;
}

.block a:hover {
  color: var(--secondary-color);
  background-color: black;
}

.block a:hover img {
  filter: invert(1);
}

.concert a,
.article a {
  margin-right: 2px;
  display: inline-block;
}

.concert a img,
.article a img {
  width: 23px;
  height: 23px;
  opacity: 0.8;
  margin-right: 5px;
  position: relative;
  top: 6.2px;
}

.contact-info a {
  color: var(--secondary-color);
  background: none !important;
  display: block;
}

.contact-info a:hover {
  color: var(--accent-color);
}

.link-button {
  border: 0;
  padding: 0;
  background: none;
  font-size: 1rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.link-button:hover {
  color: var(--accent-color);
}

.time-links {
  margin-bottom: 10px;
}

.block .date {
  color: var(--accent-color);
  margin: 0;
  font-size: 0.85rem;
}

/* Block headings */

.block h2.supertitle {
  font-size: 1.4rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.block h2 {
  font-size: 1rem;
  font-weight: 100;
  margin: 0;
}

/* Block included context */

code,
pre {
  font-family: inherit;
}

.html-content {
  margin: 1rem 0;
}

.html-content h1 {
  color: var(--secondary-color);
  font-size: 2rem;
}

.contact-info p {
  margin: 10px;
}


.collapsible.collapsed {
  max-height: 12rem;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 1.5rem, rgba(0, 0, 0, 1) 20px, transparent 90%);
  mask-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
}

.collapsible.collapsed.collapsed-long {
  max-height: 20rem;
}

/* Gallery */

.gallery-grid {
  columns: 3;
  column-gap: 7px;
}

.gallery-grid img {
  cursor: pointer;
  width: 100%;
  transition: filter 0.3s, transform 0.3s;
  margin: 2px 0;
}

.gallery-grid img.preview-image:hover {
  filter: brightness(40%);
  transform: scale(98.5%);
}

/* Responsive */

@media (max-width:800px) {
  main {
    padding-top: 15%;
    margin: 0 5%;
  }

  h1 {
    letter-spacing: 0.3rem;
    font-size: 3rem;
  }

  .hamburger {
    display: inline-block !important;
    position: absolute;
    right: 3%;
    top: 0;
    z-index: 1500;

  }

  nav ul {
    position: fixed;
    right: 0;
    top: 0;
    background-color: black;
    width: 60%;
    height: 100%;
    padding-top: 30%;
    padding-right: 5%;
    transition: right 0.5s;
    margin: 0;
    z-index: 1000;
  }

  nav ul.is-hidden {
    right: -100%;
  }

  nav ul li {
    display: block;
    font-size: 2rem;
    padding-bottom: 10%;
    text-align: end;
  }

  #social {
    position: absolute;
    left: 10%;
    top: 10px;
  }
}

@media (max-width:560px) {
  .block a {
    display: block;
    margin-bottom: 5px;
  }
}

/* Page effects */

.page-enter {
  opacity: 0;
  transform: scale(1.05);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms, transform 100ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0ms, transform 0ms;
}

.me {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.me:hover {
  opacity: 0.5;
}

/* Utility */

.separator {
  width: 5%;
  margin: 1rem 0;
  border-bottom: solid var(--accent-color) 2px;
}